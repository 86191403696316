<template>
  <div class="main-container" v-if="companyShow">
    <div class="flex just-between align-center mb20">
      <p class="rem34 c-000000 f500">我的企业</p>
      <p class="addImg" @click="addCompany">
        <img :src="addIcon" alt="" />
        <span class="rem30">添加企业</span>
      </p>
    </div>
    <div
      v-for="(item, index) in companyList"
      :key="index"
      class="flex just-between align-center company"
    >
      <div>
        <p class="rem26">企业名称</p>
        <p class="rem30 mt8 c-303133 fNormal">{{ item.enterpriseName }}</p>
        <p class="mt16 rem26">社会统一信用代码</p>
        <p class="rem30 mt8 c-303133 fNormal">{{ item.enterpriseCertNo }}</p>
      </div>
      <div>
        <img
          @click="chooseCompany(index, false)"
          :src="selectedIcon"
          alt=""
          class="img20"
          v-if="item.isSelected"
        />
        <img
          @click="chooseCompany(index, true, item)"
          :src="notSelectIcon"
          alt=""
          class="img20"
          v-else
        />
      </div>
    </div>
    <div class="button">
      <van-button
        round
        block
        type="info"
        native-type="submit"
        :disabled="!isSingle"
        @click="creditPre"
        >下一步</van-button
      >
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Button } from 'vant'

import { userCompanyListApi, creditPreApi } from '@/apis/credit'
import { mapState } from 'vuex'
import { Encrypt, Decrypt } from '@/utils/crypto'

import loadingIndex from '@/utils/overlay'
Vue.use(loadingIndex)

Vue.use(Button)

export default {
  data() {
    return {
      companyList: [],
      addIcon: require('@/assets/images/credit/add.png'),
      selectedIcon: require('@/assets/images/credit/selected.png'),
      notSelectIcon: require('@/assets/images/credit/notSelect.png'),
      isSingle: false,
      chooseData: {},
      companyShow: false,
    }
  },
  created() {
    this.getWhiteList()
  },
  computed: {
    ...mapState({
      businessType: state => state.common.businessType,
      applyChannel: state => state.user.channel.applyChannel,
    }),
  },
  methods: {
    // 获取白名单信息
    getWhiteList() {
      userCompanyListApi().then(res => {
        if (res.code === 200) {
          this.companyList = res.data || []
          this.companyShow = true
        }
      })
    },
    // 选择企业
    chooseCompany(index, flag, item) {
      // if (item.idNo.indexOf('/') == '-1') item.idNo = Decrypt(item.idNo)
      this.chooseData = item || {}
      this.$store.commit('SET_COMPANY_INFO', item)
      this.companyList[index].isSelected = flag
      let selectArr = this.companyList.filter(v => {
        return v.isSelected
      })
      this.isSingle = selectArr.length == 1
    },
    // 添加企业
    addCompany() {
      this.$router.push('/add-company-info')
    },
    showloading() {
      var title = '加载中···'
      this.$showLoading({
        title: title,
      })
    },

    hideloading() {
      this.$cancelLoading()
    },
    creditPre() {
      this.showloading()
      // 预授信获取授信申请编号
      creditPreApi({
        businessType: this.businessType, // 业务类型
        channel: '', // 申请渠道
        enterpriseSeq: this.chooseData.seqNo, // 白名单企业编码
        deviceId: '', // 设备ID
        enterpriseCertNo: this.chooseData.enterpriseCertNo, // 企业统一信用代码
        applyChannel: this.applyChannel, // 渠道
      })
        .then(res => {
          if (res.code === 200) {
            // outStr为101,102跳首页 103 node为【2000和3000的】话继续预授信 node为4000的话就可以去刷脸了
            if (res.data.outSts == 101 || res.data.outSts == 102) {
              this.$router.push({
                path: '/wait-page',
                query: {
                  outSts: res.data.outSts,
                },
              })
            } else if (res.data.outSts == 103) {
              if (res.data.node == 4000) {
                this.$store.commit('SET_CREDIT_APPLY_SEQ_NO', res.data.creditApplySeq)
                this.$router.push({
                  path: '/confirm-info',
                })
              } else if (res.data.node == 3000 || res.data.node == 2000) {
                this.$common.toast(res.data.outStsDesc)
              } else if (res.data.node == 5000) {
                this.$common.toast(res.data.outStsDesc)
              }
            }
          }
          this.hideloading()
        })
        .catch(() => {
          this.hideloading()
        })
    },
  },
}
</script>
<style lang="less" scoped>
.company {
  box-shadow: 0px 0px 0.3rem 0px rgba(0, 0, 0, 0.08);
  border-radius: 0.08rem;
  width: 100%;
  padding: 0.32rem 0.2rem 0.32rem 0.32rem;
  box-sizing: border-box;
  margin-bottom: 0.3rem;
}
.rem26 {
  color: #585c68;
}
.button {
  margin-top: 1.44rem;
}
.addImg {
  color: #2e78fb;
  img {
    width: 0.26rem;
    height: 0.26rem;
    margin-right: 0.1rem;
  }
}
</style>
